<template>

    <div class="wrapper">
        <div class="animated fadeIn">
            <!-- 제품키 정보 --------------------------------------------------------------------------------------------->
            <BCard>
                <BCard header-tag="header" footer-tag="footer" class="mb-1" >

                    <div slot="header">
                        <BIconCreditCard2FrontFill/> <strong> 인증토큰 </strong>
                        <div class="card-header-actions">
                            <small class="text-muted">인증토큰 리스트</small>
                        </div>
                    </div>

                    <BRow class="mb-2">
                        <BCol sm="3">
                            <strong>Total Records: {{ rows.length }}</strong>
                        </BCol>
                        <BCol sm="2">
                            <BInputGroup size="sm" prepend="검색기간">
                                <BFormSelect v-model="daySelected"
                                             size = "sm"
                                             @input="setSearchDate"
                                             :options = "dayOpts"/>
                            </BInputGroup>
                        </BCol>
                        <BCol>
                            <BInputGroup >
                                <template v-slot:prepend>
                                    <BFormSelect size="sm"
                                                 v-model="searchField"
                                                 :options="searchOpts"/>
                                </template>
                                <BFormInput size="sm"
                                            v-model="searchWord"
                                            type="text"
                                            @keyup.enter="getProductTokenList"/>
                            </BInputGroup>
                        </BCol>
                        <BCol>
                            <BButtonGroup>
                                <BButton size="sm" variant="primary" @click="getProductTokenList"><BIconArrowRepeat/> Refresh</BButton>
                                <BButton size="sm" variant="info" @click="exportExcel" class="ml-1"><BIconFileSpreadsheetFill/> 다운로드</BButton>
                            </BButtonGroup>
                        </BCol>

                    </BRow>

                    <BRow>
                        <BCol>
                            <vue-excel-editor
                                    v-model="rows"
                                    ref="productTokenGrid"
                                    width="100%"
                                    class="mb-1"
                                    :page="numOfRecord"
                                    :readonly-style="{backgroundColor:'#EFE'}"
                                    @update="updateProductToken"
                                    @delete="deleteProductToken"
                                    @select="selectProductToken"
                                    :localized-label="$localizedLabel"
                                    filter-row>
                                <vue-excel-column field="key_seq" label="키번호" type="number" width="60px"  readonly/>
                                <vue-excel-column field="token_seq" label="토큰번호" type="number" width="70px" key-field readonly/>
                                <vue-excel-column field="prd_token" label="토큰" width="150px" readonly/>
                                <vue-excel-column field="prd_name" label="제품명" width="100px"/>
                                <vue-excel-column field="ord_no" label="Order" type="number" width="60px"/>
                                <vue-excel-column field="token_stat" label="상태" type="map" :options="tokenStatusOpt" width="80px"/>
                                <vue-excel-column field="exp_dt" label="만료일" type="date" width="110px"/>
                                <vue-excel-column field="version" label="버전" width="60px"/>
<!--                                <vue-excel-column field="salt" label="SALT" width="50px" readonly/>-->
                                <vue-excel-column field="auth_cnt" label="인증횟수" type="number" width="80px" readonly/>
                                <vue-excel-column field="allow_cnt" label="허용횟수" type="number" width="80px"/>
                                <vue-excel-column field="accs_yn" label="실행" type="map" width="60px" :options="{'Y':'실행', 'N':'종료'}"/>
                                <vue-excel-column field="accs_dt" label="접속일시"  type="datetime" width="158px" readonly/>
                                <vue-excel-column field="close_dt" label="종료일시"  type="date" width="110px" readonly/>
                                <vue-excel-column field="accs_ip" label="IP"  width="140px" readonly/>
                                <vue-excel-column field="upd_dt" label="수정일" type="datetime" width="150px" readonly/>
                                <vue-excel-column field="reg_dt" label="등록일" type="datetime" width="150px" readonly/>
                            </vue-excel-editor>
                        </BCol>
                    </BRow>


                    <!-- claim log table -->

                    <BRow>
                        <BCol sm="7">
                            <BCard>
                                <div slot="header">
                                    <div slot="header">
                                        <BIconBookFill/> <strong> 접속로그 </strong>
                                    </div>
                                </div>
                            <vue-excel-editor
                                    v-model="claimLogs"
                                    ref="claimLogsGrid"
                                    width="100%"
                                    class="mt-2"
                                    :page="20"
                                    :localized-label="$localizedLabel"
                                    filter-row>
<!--                                <vue-excel-column field="seq_no" label="번호" type="number" width="60px" key-field readonly/>-->
                                <vue-excel-column field="key_seq" label="키번호" type="number" width="60px" readonly/>
                                <vue-excel-column field="token_seq" label="토큰" type="number" width="60px" readonly/>
                                <vue-excel-column field="accs_ip" label="IP주소"  width="120px" readonly/>
                                <vue-excel-column field="log_type" label="로그타입" type="map" :options="logTypeOpts" width="100px"/>
                                <vue-excel-column field="result" label="결과" width="220px"/>
                                <vue-excel-column field="reg_dt" label="접속일" type="datetime" width="160px" readonly/>
                            </vue-excel-editor>
                                <BRow>
                                    <BCol>
                                        <BButton variant="info" class="mt-1" v-if="selectedRow!=null" @click="getClaimLogs">
                                            <BIconSearch/> 접속로그 전체 조회 {{selectedRow.key_seq}}
                                        </BButton>
                                    </BCol>
                                </BRow>
                            </BCard>
                        </BCol>
                        <BCol>
                            <BCard>
                                <div slot="header">
                                    <BIconCartCheckFill/> <strong> 구매정보 </strong>
                                </div>
                                <BRow v-if="ordInfo!==null">
                                    <BCol sm="3">구매일</BCol><BCol sm="9" class="text-right">{{ordInfo.ord_dt}}</BCol>
                                    <BCol sm="3">성명</BCol><BCol sm="9" class="text-right">{{ordInfo.name}}</BCol>
                                    <BCol sm="3">회사명</BCol><BCol sm="9" class="text-right">{{ordInfo.comp_name}}</BCol>
                                    <BCol sm="3">연락처</BCol><BCol sm="9" class="text-right">{{ordInfo.phone_no}}</BCol>
                                    <BCol sm="3">email</BCol><BCol sm="9" class="text-right">{{ordInfo.email}}</BCol>
                                    <BCol sm="3">주소</BCol><BCol sm="9" class="text-right">{{ordInfo.address}}</BCol>
                                </BRow>
                            </BCard>
                        </BCol>
                    </BRow>
                </BCard>
            </BCard>

        </div>
    </div>
</template>

<script>
    //-------------------------------------------------------------------------------------------------
    import {alertModal, apiCall, cloneVar, confirmModal, checkBisNo} from '../../../common/utils';
    import qs from 'querystring';
    import moment from "moment";
    //----------------------------------------------------------------------------------------------------
    export default {
        name: 'ProductToken',
        data () {
            return {
                searchOpts: [
                    {value:'key_seq',    text:'키번호'},
                    {value:'token_seq',  text:'토큰번호'},
                    {value:'prd_key',    text:'제품키'},
                    {value:'accs_ip',    text:'IP주소'},
                    {value:'token_stat', text:'토큰상태'},
                    {value:'version',    text:'버전'}
                ],
                dayOpts: [
                    {value:7,    text:'7일'},
                    {value:14,    text:'14일'},
                    {value:30,    text:'30일'},
                    {value:90,    text:'90일'},
                    {value:120,    text:'120일'},
                    {value:365,    text:'1년'},
                    {value:null,    text:'전체'},
                ],
                daySelected: 7,
                tokenStatusOpt: {'00':'활성', '10':'준비', '90': '추출', '99':'만료'},
                logTypeOpts: {'I':'접속', 'O':'종료','S':'설치'},

                rows: [],
                claimLogs: [],
                ordInfo: null,
                selectedIdx: null,
                selectedRow: null,
                fromDate: moment().subtract(7,"days").format('YYYY-MM-DD'),
                /////////////////////////////////////////////////////
                searchWord : null,
                searchField: 'prd_key',

                numOfRecord: 20,
            }

        },
        async created(){
            try{
                await this.getProductTokenList();
            }catch(err){
                console.log(err);
            }
        },
        computed: {

        },
        mounted() {
            console.log("mounted---------------------ProductToken");
            // below is not work!
        },

        methods: {

            async getProductTokenList(){

                let r = null, param = {};
                let qry = '';
                if( this.searchWord !== null) param[this.searchField] = this.searchWord.toUpperCase();
                if( this.fromDate !== null) param['fromDate'] = this.fromDate;
                qry = qs.stringify( param );
                console.log( "query-string ---------- ", qry );
                try{
                    this.rows = [];
                    r = await apiCall('get', `/admin/api/product/token?${qry}`);
                    if(r.result) this.rows = r.result;

                }catch(err){
                    console.log( err );
                }

            },
            setSearchDate(){
                if(this.daySelected!==null){
                    this.fromDate = moment().subtract(this.daySelected, "days").format("YYYY-MM-DD");
                    console.log( "setSearchDate -----> fromDate: ", this.fromDate );
                }else{
                    this.fromDate = null;
                }
                this.getProductTokenList();
            },
            exportExcel(){
                const format = 'xlsx';
                const exportSelectedOnly = true;
                const filename = 'product-token-'+moment().format('YYYY-MM-DD');
                this.$refs.productTokenGrid.exportTable(format, exportSelectedOnly, filename);
            },

            async getClaimLogs(str=null){
                console.log( 'getClaimLogs ---', this.selectedRow );
                let r = null, rr = null;
                let row = this.selectedRow;
                let qry = qs.stringify( {key_seq: row.key_seq, token_seq: (str==='token')? row.token_seq:null} );
                // let qry = qs.stringify( {key_seq: row.key_seq} );

                try{
                    this.claimLogs = [];
                    r = await apiCall('get', `/admin/api/product/claim-log?${qry}`);
                    // console.log( r.result );
                    if(r.result) this.claimLogs = r.result;

                    console.log( "this.selectedRow -----------> ", row );
                    if( row.ord_no ){
                        rr = await apiCall('get', `/admin/api/sales/product/${row.ord_no}`);
                        console.log( rr );
                        this.ordInfo = rr.result[0];
                    }
                }catch(err){
                    console.log( err );
                }
            },


            async selectProductToken(idx, evt){
              if(!evt) return;
              // console.log( "selectProductToken --------> ", evt );
              // console.log( "selectProductToken --------> ", this.rows[idx] );
                this.selectedRow = null;
                this.claimLogs = [];
                this.ordInfo = null;
              try{
                const arr = this.$refs['productTokenGrid'].getSelectedRecords();
                this.selectedRow = arr[0];
                await this.getClaimLogs('token');
              }catch(err){
                  console.log(err);
              }
            },

            async updateProductToken(rec){
                let r = null, msg = null, notice = null;

                try{
                    let param = {};
                    const tokenSeq = rec[0].keys[0];
                    const fieldName = rec[0].name;
                    const newVal = rec[0].newVal;

                    param['token_seq'] = tokenSeq;
                    param[fieldName] = newVal;

                    r = await apiCall("PUT", `/admin/api/product/token`, param);

                    if( !r.result || !r.result.affectedRows ){
                        await alertWarn( this.$bvModal, `Message: ${r.message}`, "[에러] 업데이트 실패");
                        this.$refs.productTokenGrid.undoTransaction();
                    }

                }catch(err){
                    console.log( err );
                }
            },

            async deleteProductToken(recs){
                let r = null, msg = null, notice = null;

                try{
                    console.log( recs );
                    /*
                    r = await apiCall("DELETE", `/api/setting/company/${this.agt.comp_no}`, agt);
                    msg = r.error;

                    if( r.error.code==='200'){
                        notice = {title: `[${msg.code}] 성공`, text : '삭제 되었습니다.', level: 'warn'};
                    }else{
                        notice = {title: `[${msg.code}] 에러`, text : msg.title, level: 'err'};
                    }

                    alertModal( this.$bvModal, notice, (ok)=>{
                        if(ok) this.getCompList();
                    });*/
                }catch(err){

                    console.log( err );
                }
            },


        }
    }
</script>
